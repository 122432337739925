<template>
  <div>
    <div v-if="locationAreas.length > 1">
      <el-popover placement="top-end" trigger="hover">
        <ul>
          <li v-for="(area, i) in locationAreas" :key="i">
            {{ area.name }}
          </li>
        </ul>
        <p slot="reference">{{ locationAreas.length }} Location areas</p>
      </el-popover>
    </div>
    <p v-else-if="locationAreas.length === 1">
      {{ locationAreas[0].name }}
    </p>
    <p v-else>-</p>
  </div>
</template>

<script>
export default {
  name: "RiderLocationAreas",
  props: {
    locationAreas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>
